import { qsa } from "./utils.js"

export function initGalleries() {
  let activeGallery

  const activate = (el) => {
    activeGallery = el
    activeGallery.classList.add("is-active")
  }

  const close = () => {
    for (const el of qsa(".gallery details[open]")) {
      el.open = false
    }
    activeGallery?.classList.remove("is-active")
    activeGallery = null
  }

  const go = (delta) => {
    const images = Array.from(qsa(".gallery__image", activeGallery))
    const active = images.findIndex((image) => image.open)
    images[active].open = false
    images[(active + images.length + delta) % images.length].open = true
  }

  document.body.addEventListener("click", (e) => {
    let el
    if ((el = e.target.closest(".gallery__full"))) {
      close()
    } else if ((el = e.target.closest(".gallery__image"))) {
      activate(el.closest(".gallery"))
    } else if ((el = e.target.closest(".gallery__controls"))) {
      e.preventDefault()
      go(el.classList.contains("gallery__previous") ? -1 : +1)
    }
  })

  document.body.addEventListener("keydown", (e) => {
    if (e.key === "Escape") {
      close()
    } else if (activeGallery && e.key === "ArrowLeft") {
      go(-1)
    } else if (activeGallery && e.key === "ArrowRight") {
      go(+1)
    }
  })
}

export function initSlider() {
  const slides = Array.from(qsa(".intro__slide"))
  if (slides.length) {
    // Start with the last slide, it's the one which is initially visible anyway
    let pos = slides.length - 1
    let slide = slides[pos]
    slide.classList.add("current")

    setInterval(() => {
      slide.classList.remove("current")
      slide = slides[++pos % slides.length]
      slide.classList.add("current")
    }, 2000)
  }
}
