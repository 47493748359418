import { qs, qsa } from "./utils.js"

export function toggleMenuHandler() {
  const toggle = qs("#sl-toggle")
  const eh = qs("#expand-menu")
  const menu = qs(".menu__menu")

  if (toggle && eh) {
    document.body.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        eh.checked = false
        eh.dispatchEvent(new Event("change"))
      }
    })
  }

  function setAria(trigger, target) {
    if (trigger && target) {
      trigger.addEventListener("change", () => {
        target.setAttribute("aria-hidden", !trigger.checked)
        target.setAttribute("aria-expanded", trigger.checked)
      })
    }
  }

  if (eh && toggle) {
    eh.addEventListener("change", () => {
      document.body.classList.toggle("-menu-expanded", eh.checked)
    })
    setAria(eh, menu)
  }

  for (const submenu of qsa(".menu__submenu", menu)) {
    const trigger = qs(`#expand-${submenu.id}`, menu)
    setAria(trigger, submenu)
  }
}
