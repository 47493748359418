import "./reset.css"
import "./grid.css"
import "./flow.css"
import "./functional.css"
import "./icons.css"

import "./static/assets/fonts/fonts.css"
import "./vars.css"
import "./body.css"
import "./admin.css"

import "./layout.css"
import "./angles.css"
import "./articles.css"
import "./buttons.css"
import "./cells.css"
import "./f3cc.css"
import "./cta.css"
import "./customers.css"
import "./darkmode.css"
import "./details.css"
import "./directives.css"
import "./embeds.css"
import "./filters.css"
import "./flips.css"
import "./footer.css"
import "./forms.css"
import "./galleries.css"
import "./header.css"
import "./intro.css"
import "./masonry.css"
import "./menu.css"
// import "./messages.css"
import "./newsletter.css"
// import "./pagination.css"
import "./projects.css"
import "./project-detail.css"
import "./prose.css"
import "./quotations.css"
import "./services.css"
/* import "./sidebar.css" */
import "./team.css"
import "./titles.css"
import "./consultant.css"

import { initAfterDark, initDarkModeCycle } from "./darkmode.js"
import { setDetailsHeights } from "./details.js"
import { initFilters } from "./filters.js"
// import { initForms } from "./forms.js"
import { initGalleries, initSlider } from "./galleries.js"
import { initHypermedia } from "./hypermedia.js"
import { initMasonry } from "./masonry.js"
import { toggleMenuHandler } from "./menu.js"
import { onReady } from "./utils.js"

onReady(initDarkModeCycle)
onReady(initAfterDark)
onReady(initFilters)
// onReady(initForms)
onReady(initGalleries)
onReady(initSlider)
onReady(initMasonry)
onReady(initHypermedia)

onReady(() => {
  setDetailsHeights("details")
})

onReady(toggleMenuHandler)

onReady(() => {
  const body = document.body

  if (window.matchMedia("(any-pointer: coarse)").matches) {
    body.classList.add("is-touch")
  }

  body.addEventListener("click", (e) => {
    const el = e.target.closest(".team__image-wrapper:has(.team__image--back)")
    if (el) {
      el.classList.toggle("flipped")
    }
  })
})

onReady(() => {
  const root = document.getElementById("fake-or-not")
  if (root) {
    import("./fake-or-not/fake-or-not.js").then((mod) => {
      mod.initFakeOrNot(root)
    })
  }
})

const noModernizrButModernizr = () => {
  const el = document.documentElement
  el.classList.remove("no-js")
  el.classList.add("js")
}
noModernizrButModernizr()
